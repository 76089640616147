import api from '@/boot/api'
import i18n from '@/boot/i18n'
import cloneDeep from 'lodash/cloneDeep'
import now from 'lodash/now'
import sortBy from 'lodash/sortBy'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/store/shared'
import Vue from 'vue'
import { pascalCase, sentence } from '@/utilities/filters'

const defaultCachePeriodMs = 5 * 60 * 1000

const defaultState = () => ({
  audits: { data: [] },
  billingDefaultPriceLists: { data: [] },
  billingManualHeaders: { data: [] },
  billingPriceLists: { data: [] },
  containerTypeCodes: { data: [] },
  dateRange: [
    format(startOfMonth(Date.now()), 'yyyy-MM-dd'),
    format(endOfMonth(Date.now()), 'yyyy-MM-dd'),
  ],
  fulfilmentDefaultPriceLists: { data: [] },
  fulfilmentPriceLists: { data: [] },
  hubooBoxes: { data: [] },
  manualDefaultPriceLists: { data: [] },
  manualPriceLists: { data: [] },
  multiItemHeaders: { data: [] },
  pickRateHeaders: { data: [] },
  pickRatePriceLists: { data: [] },
  priceListVersions: { data: [] },
  priceListVersionsLastFetched: null,
  propertiesLoading: { data: [] },
  regions: { data: [] },
  selectedBillingManualHeader: null,
  selectedBillingPriceList: null,
  selectedBox: null,
  selectedFulfilmentPriceList: null,
  selectedFulfilmentDefaultPriceList: null,
  selectedManualPriceList: null,
  selectedPickRatePriceList: null,
  selectedPriceListVersionId: null,
  selectedRegion: null,
  selectedSkuSpecificPriceList: null,
  shippingServices: { data: [] },
  skuSpecificPriceLists: { data: [] },
  storageBilling: { data: [] },
  subscriptionBilling: { data: [] },
  zones: { data: [] },
})

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(cloneDeep(properties))

const defaultMutations = makeDefaultMutations(properties, defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
  getOriginRegions: state => state.regions.data?.filter(region => region.origin === 1),
  getBoxById: state => id => cloneDeep(state.hubooBoxes?.data?.find(d => d.hubooBox === id)),
  getContainerTypeCodes: state => cloneDeep(state.containerTypeCodes?.data),
  getBillingDefaultPriceListById: state => id =>
    cloneDeep(state.billingDefaultPriceLists?.data?.find(d => d.id === id)),
  getManualDefaultPriceListById: state => id =>
    cloneDeep(state.manualDefaultPriceLists?.data?.find(d => d.id === id)),
  getManualPriceListById: state => id =>
    cloneDeep(state.manualPriceLists?.data?.find(d => d.id === id)),
  getPickRatePriceLists: state => cloneDeep(state.pickRatePriceLists),
  getSelectedBox: state => cloneDeep(state.selectedBox?.data),
  getSelectedBillingManualHeader: state => cloneDeep(state.selectedBillingManualHeader?.data),
  getSelectedBillingPriceList: state => cloneDeep(state.selectedBillingPriceList?.data),
  getSelectedFulfilmentDefaultPriceList: state =>
    cloneDeep(state.selectedFulfilmentDefaultPriceList?.data),
  getSelectedFulfilmentPriceList: state => cloneDeep(state.selectedFulfilmentPriceList?.data),
  getSelectedManualPriceList: state => cloneDeep(state.selectedManualPriceList?.data),
  getSelectedPickRatePriceList: state => cloneDeep(state.selectedPickRatePriceList?.data),
  getSelectedSkuSpecificPriceList: state => cloneDeep(state.selectedSkuSpecificPriceList?.data),
  getVisibleShippingServices: state => state.shippingServices.data?.filter(s => !s.hidden),
  getRowZones: state => state.zones.data?.filter(z => z.region === 'ROW'),
}

const actions = {
  async fetchPriceListVersions({ state, dispatch, commit }, p = {}) {
    const { force, ...requestPayload } = p
    const shouldRefresh = now() - state.priceListVersionsLastFetched > defaultCachePeriodMs

    if (!force && !shouldRefresh && state.priceListVersions.data.length) return

    await dispatch('sendAndSet', {
      mutation: 'setPriceListVersions',
      payload: { ...requestPayload, url: 'price-list-version', method: 'GET' },
      reset: false,
    })

    commit('setPriceListVersionsLastFetched', now())

    if (state.selectedPriceListVersionId) return

    const defaultPriceListVersion = sortBy(
      state.priceListVersions?.data ?? [],
      plv => -plv.isDefault ?? 0
    )[0]
    if (defaultPriceListVersion) {
      commit('setSelectedPriceListVersionId', defaultPriceListVersion.id)
    }
  },
  async fetchBillingDefaultPriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setBillingDefaultPriceLists',
      payload: { ...p, url: 'billing-default-price-list', method: 'GET' },
    })
  },
  async fetchBillingManualHeaders({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setBillingManualHeaders',
      payload: { ...p, method: 'GET' },
    })
  },
  async fetchBillingPriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setBillingPriceLists', payload: { ...p, method: 'GET' } })
  },
  async fetchBillingPriceListById({ dispatch }, id) {
    dispatch('sendAndSet', {
      mutation: 'setSelectedBillingPriceList',
      payload: { url: '/billing-price-list/' + id, method: 'GET' },
    })
  },
  async fetchClientBillingSetups({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setHubooBoxes',
      payload: { ...p, url: 'client-billing-setups', method: 'GET' },
    })
  },
  async fetchClientBillingSetupByHubooBox({ dispatch }, id) {
    dispatch('sendAndSet', {
      mutation: 'setSelectedBox',
      payload: { url: 'huboo-box/' + id + '/client-billing-setups/view', method: 'GET' },
      reset: false,
    })
  },
  async fetchClientBillingSetupById({ dispatch }, id) {
    dispatch('sendAndSet', {
      mutation: 'setSelectedBox',
      payload: { url: 'client-billing-setups/' + id, method: 'GET' },
      reset: false,
    })
  },
  async fetchContainerTypeCodes({ dispatch }, payload) {
    dispatch('sendAndSet', { mutation: 'setContainerTypeCodes', payload })
  },
  async fetchFulfilmentDefaultPriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setFulfilmentDefaultPriceLists',
      payload: { ...p, method: 'GET' },
    })
  },
  async fetchFulfilmentPriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setFulfilmentPriceLists',
      payload: { ...p, method: 'GET' },
    })
  },
  async fetchManualDefaultPriceLists({ dispatch, getters }, p = {}) {
    p.params = {
      priceListVersion: getters.getSelectedBox.priceListVersionId,
    }
    dispatch('sendAndSet', {
      mutation: 'setManualDefaultPriceLists',
      payload: { ...p, url: 'manual-default-price-list', method: 'GET' },
    })
  },
  async fetchManualPriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setManualPriceLists',
      payload: { ...p, method: 'GET' },
    })
  },
  async fetchMultiItemHeaders({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setMultiItemHeaders', payload: { ...p, method: 'GET' } })
  },
  async fetchPickRateHeaders({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setPickRateHeaders', payload: { ...p, method: 'GET' } })
  },
  async fetchPickRatePriceLists({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setPickRatePriceLists', payload: { ...p, method: 'GET' } })
  },
  async fetchAudits({ dispatch }, p = {}) {
    dispatch('sendAndSet', {
      mutation: 'setAudits',
      payload: { ...p, method: 'GET', shouldCameliseResponse: false },
    })
  },
  async fetchRegions({ dispatch }) {
    dispatch('sendAndSet', {
      mutation: 'setRegions',
      payload: { url: 'region', method: 'GET' },
      reset: false,
    })
  },
  async fetchShippingServices({ dispatch }) {
    dispatch('sendAndSet', {
      mutation: 'setShippingServices',
      payload: { url: 'billing-shipping-services', method: 'GET' },
      reset: false,
    })
  },
  async fetchSubscriptionBilling({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setSubscriptionBilling', payload: { ...p, method: 'GET' } })
  },
  async fetchStorageBilling({ dispatch }, p = {}) {
    dispatch('sendAndSet', { mutation: 'setStorageBilling', payload: { ...p, method: 'GET' } })
  },
  async fetchSkuSpecificPriceLists({ dispatch }, p = {}) {
    const mutation = 'setSkuSpecificPriceLists'
    dispatch('sendAndSet', { mutation, payload: { ...p, method: 'GET' } })
  },
  async fetchZones({ dispatch }) {
    dispatch('sendAndSet', {
      mutation: 'setZones',
      payload: { url: 'zones', method: 'GET' },
      reset: false,
    })
  },
  handleRemoveFailure({ commit }, { color = 'error', target = i18n.t('common.item') }) {
    const message = sentence(i18n.t('common.failedToRemoveTheX', { x: target }))
    commit('core/launchSnackbar', { color, message }, { root: true })
  },
  handleRemoveSuccess({ commit }, { color = 'success', id, target = i18n.t('common.item') }) {
    const message = sentence(i18n.t('common.successfullyRemovedTheX', { x: target }))
    commit('remove' + pascalCase(target) + 'ById', id)
    commit('core/launchSnackbar', { color, message }, { root: true })
  },
  handleCsvUploadFailure({ commit }) {
    const message = i18n.t('billing.failedToUploadCsv')
    commit('core/launchSnackbar', { color: 'error', message }, { root: true })
  },
  handleCsvUploadSuccess({ commit }) {
    const message = i18n.t('billing.successfullyUploadedCsv')
    commit('core/launchSnackbar', { color: 'success', message }, { root: true })
  },
  handlePatchSuccess({ commit }, { target }) {
    const message = i18n.t('common.successfullyUpdatedX', { x: target })
    commit('core/launchSnackbar', { color: 'success', message }, { root: true })
  },
  async patchBillingPriceLists({ dispatch }, { id, data } = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedBillingPriceList',
      payload: { url: '/billing-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchBillingManualHeaders({ dispatch }, { id, data } = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedBillingManualHeader',
      payload: { url: '/billing-manual-header/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchClientBillingSetups({ dispatch }, { id, data } = {}) {
    const res = await dispatch('sendAndSet', {
      mutation: 'setSelectedBox',
      payload: { url: 'client-billing-setups/' + id, data, method: 'PATCH' },
      reset: false,
    })
    if (res?.status === 200) dispatch('handlePatchSuccess', { target: 'client details' })
    return res
  },
  async patchManualPriceLists({ dispatch }, { id, data } = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedManualPriceList',
      payload: { url: '/manual-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchPickRateContainerPriceLists({ dispatch }, { id, data } = {}) {
    return await dispatch('sendAndSet', {
      mutation: 'setSelectedPickRatePriceList',
      payload: { url: 'pick-rate-container-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchPickRateNumberPriceLists({ dispatch }, { id, data } = {}) {
    return await dispatch('sendAndSet', {
      mutation: 'setSelectedPickRatePriceList',
      payload: { url: 'pick-rate-number-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchFulfilmentDefaultPriceLists({ dispatch }, { id, data } = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedFulfilmentDefaultPriceList',
      payload: { url: 'fulfilment-default-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchFulfilmentPriceLists({ dispatch }, { id, data } = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedFulfilmentPriceList',
      payload: { url: 'fulfilment-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async patchSkuSpecificPriceLists({ dispatch }, { id, data } = {}) {
    return await dispatch('sendAndSet', {
      mutation: 'setSelectedSkuSpecificPriceList',
      payload: { url: 'sku-specific-price-list/' + id, data, method: 'PATCH' },
      reset: false,
    })
  },
  async postBillingPriceLists({ dispatch }, data = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedBillingPriceList',
      payload: { url: '/billing-price-list', data, method: 'POST' },
      reset: false,
    })
  },
  async storePricingUpload({ dispatch }, { url, file, type, priceListVersionId } = {}) {
    let data = new FormData()
    data.append('file', file)
    data.append('type', type) // Currently has no use, placeholder for future uploads
    data.append('priceListVersion', priceListVersionId)

    const res = await dispatch('send', {
      method: 'POST',
      url,
      data,
    })

    if (res?.status === 202) dispatch('handleCsvUploadSuccess')
    else dispatch('handleCsvUploadFailure')
  },
  async postBillingManualHeaders({ dispatch }, data = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedBillingManualHeader',
      payload: { url: '/billing-manual-header', data, method: 'POST' },
      reset: false,
    })
  },
  async postFulfilmentDefaultPriceLists({ dispatch }, data = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedFulfilmentDefaultPriceList',
      payload: { url: '/fulfilment-default-price-list', data, method: 'POST' },
    })
  },
  async postFulfilmentPriceLists({ dispatch }, data = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedFulfilmentPriceList',
      payload: { url: '/fulfilment-price-list', data, method: 'POST' },
    })
  },
  async postManualPriceLists({ dispatch }, data = {}) {
    await dispatch('sendAndSet', {
      mutation: 'setSelectedManualPriceList',
      payload: { url: '/manual-price-list', data, method: 'POST' },
      reset: false,
    })
  },

  async removePickRateContainerPriceLists({ dispatch }, { id, url } = {}) {
    const res = await dispatch('send', { url, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'pickRatePriceList' })
    else dispatch('handleRemoveFailure', { target: 'pickRatePriceList' })
  },
  async removePickRateNumberPriceLists({ dispatch }, { id, url } = {}) {
    const res = await dispatch('send', { url, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'pickRatePriceList' })
    else dispatch('handleRemoveFailure', { target: 'pickRatePriceList' })
  },
  async removeSkuSpecificPriceList({ dispatch }, { id, url } = {}) {
    const res = await dispatch('send', { url, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'skuSpecificPriceList' })
    else dispatch('handleRemoveFailure', { target: 'skuSpecificPriceList' })
  },
  async removeBillingManualHeaders({ dispatch }, { id, url } = {}) {
    const res = await dispatch('send', { url, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'billingManualHeader' })
    else dispatch('handleRemoveFailure', { target: 'billingManualHeader' })
  },
  async removeFulfilmentDefaultPriceLists({ dispatch }, { id, url } = {}) {
    const res = await dispatch('send', { url: url + `/${id}`, method: 'DELETE' })
    if (res?.status === 204)
      dispatch('handleRemoveSuccess', { id, target: 'fulfilmentDefaultPriceLists' })
    else dispatch('handleRemoveFailure', { target: 'fulfilmentDefaultPriceLists' })
  },
  async removeFulfilmentPriceLists({ dispatch }, { id } = {}) {
    const res = await dispatch('send', { url: `fulfilment-price-list/${id}`, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'fulfilmentPriceLists' })
    else dispatch('handleRemoveFailure', { target: 'fulfilmentPriceLists' })
  },
  async removeManualPriceLists({ dispatch }, { id } = {}) {
    const res = await dispatch('send', { url: `manual-price-list/${id}`, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'manualPriceLists' })
    else dispatch('handleRemoveFailure', { target: 'manualPriceLists' })
  },
  async removeBillingPriceLists({ dispatch }, { id } = {}) {
    const res = await dispatch('send', { url: `billing-price-list/${id}`, method: 'DELETE' })
    if (res?.status === 204) dispatch('handleRemoveSuccess', { id, target: 'billingPriceLists' })
    else dispatch('handleRemoveFailure', { target: 'billingPriceLists' })
  },
  async send(...[, payload]) {
    return await api(payload?.url, payload)
  },
  async sendAndSet({ commit, dispatch }, { mutation, payload, reset = true }) {
    if (reset) commit('re' + mutation)
    const res = await dispatch('send', payload)
    if (res?.data) commit(mutation, res.data)
    return res
  },
  async storePickRateContainerPriceLists({ dispatch }, { data } = {}) {
    return await dispatch('send', { url: 'pick-rate-container-price-list/', data, method: 'POST' })
  },
  async storePickRateNumberPriceLists({ dispatch }, { data } = {}) {
    return await dispatch('send', { url: 'pick-rate-number-price-list/', data, method: 'POST' })
  },
  async storeSkuSpecificPriceList({ dispatch }, { data } = {}) {
    return await dispatch('send', { url: 'sku-specific-price-list/', data, method: 'POST' })
  },
}

const mutations = {
  ...defaultMutations,
  removePickRatePriceListById(state, id) {
    const filtered = state.pickRatePriceLists.data.filter(l => l.id !== id)
    Vue.set(state.pickRatePriceLists, 'data', filtered)
  },
  removeSkuSpecificPriceListById(state, id) {
    const filtered = state.skuSpecificPriceLists.data.filter(l => l.id !== id)
    Vue.set(state.skuSpecificPriceLists, 'data', filtered)
  },
  removeBillingManualHeaderById(state, id) {
    const filtered = state.billingManualHeaders.data.filter(l => l.id !== id)
    Vue.set(state.billingManualHeaders, 'data', filtered)
  },
  removeFulfilmentDefaultPriceListsById(state, id) {
    const filtered = state.fulfilmentDefaultPriceLists.data.filter(l => l.id !== id)
    Vue.set(state.fulfilmentDefaultPriceLists, 'data', filtered)
  },
  removeFulfilmentPriceListsById(state, id) {
    const filtered = state.fulfilmentPriceLists.data.filter(l => l.id !== id)
    Vue.set(state.fulfilmentPriceLists, 'data', filtered)
  },
  removeManualPriceListsById(state, id) {
    const filtered = state.manualPriceLists.data.filter(l => l.id !== id)
    Vue.set(state.manualPriceLists, 'data', filtered)
  },
  removeBillingPriceListsById(state, id) {
    const filtered = state.billingPriceLists.data.filter(l => l.id !== id)
    Vue.set(state.billingPriceLists, 'data', filtered)
  },
  resetState: (state, replacement) => {
    setState(state, replacement || defaultState())
  },
}

const mod = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}

export default mod
